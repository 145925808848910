.wave-effect {
    animation: pulse 1s infinite alternate;
  }
  
  @keyframes pulse {
    from {
      transform: scale(1);
      opacity: 0.5;
    }
    to {
      transform: scale(2);
      opacity: 0;
    }
  }